:root {
  --background-color-light: #f8f8f8;
  --background-color-dark: #f8f8f8;
  --text-color-light: #f8f8f8;
  --text-color-dark: #f8f8f8;
}

body.light {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
}

body.dark {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
}

.App {
  text-align: center;
}

.App.light .App-header {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

.App.dark .App-header {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.spin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.container {
  display: flex;
  flex-direction: column;
  
 flex: 1;
  background-color: #f8f8f8;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;

  @media (max-width: 768px) {
    
    min-height: 100vh;
  }

  @media (max-width: 375px) {
    
    min-height: 50vh;
  }
}

.container_header {
background-color:black ;
}

.content {
  flex: 1;
  
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100%; 
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-width: 1;
  border-color: black;

  @media (max-width: 768px) {
    
    background-color: #ffffff;
  }

  @media (max-width: 375px) {
    flex: 1;
    
    background-color: #ffffff;
  }
}
